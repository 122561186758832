html,body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat";
}
@font-face {
  font-family: "Montserrat";
  src: url('./fonts/ttf/Montserrat.ttf');

}

/* @font-face {
  font-family: "bronovo-bold";

  src: url('./fonts/ttf/Bronova\ Bold.ttf');

} */
.gayn-web p,a,span,small,li {
  font-family: "Montserrat";
}
.gayn-web button {
  font-family: "Montserrat";
}
.gayn-web h1,h2,h3,h4,h5,h6 {
  font-weight: 600;
  font-family: "Montserrat";
}
.gayn-web .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gayn-web .App-link {
  color: #61dafb;
}

/* Boo styles start */
.gayn-web .main-nav .navbar {
  background-color: #0f131f;
}
.gayn-web .main-nav .navbar .navbar-nav .nav-item .nav-link {
  color: #3CADB5;
  font-size: 11px;
  padding: 4px 5px 0px 5px;
  font-weight: 700;
}
.gayn-web .main-nav .navbar .navbar-nav .nav-item .bg-blue {
  background-color: #3CADB5;
  color: #2a3e4a;
  
}
.gayn-web .main-nav .navbar .navbar-collapse {
  justify-content: flex-end;
}
.gayn-web .logo {
  width: 100%;
}
.gayn-web .capital-text {
  display: block;
  text-align: center;
  font-size: 11px;
  letter-spacing: 3px;
  font-weight: 700;
  margin-top: 5px;
  color: #76787f;
}
.gayn-web .slider-sec .bg-slide {
  background-image: url('./assets/banner/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  
}
.gayn-web .login-sec a {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-right: 5px solid #3cacb4;
    padding: 0px 20px 0px 20px;
}
.gayn-web .login-sec a:hover {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  border-right: 5px solid #3cacb4;
  padding: 0px 20px 0px 20px;
  text-decoration: none;
}
.gayn-web .banner-head h3 {
  margin-bottom: 20px;
  font-weight: 700;
  margin-top: 12px;
}
.gayn-web .login-sec {
  margin-bottom: 20px;
}
.gayn-web .view-sec button {
  background-color: #262632;
  border: 0px;
  color: #37929b;
  padding: 10px 15px 10px 15px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  margin-top: 12px;
}
.gayn-web .slider-sec .carousel-caption {
  position: inherit;
  right: 0;
  bottom: inherit;
  left: 0;
  z-index: 10;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding: 12% 0%;
  color: #3cadb5;
}
.gayn-web .slider-sec .overlay {
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0.5;
}
.gayn-web .bg-sky {
  background-color: #3cadb5;
  padding: 8px;
}
.gayn-web .bg-sky p {
  color: #000;
  margin-bottom: 0px;
}
.gayn-web .blur-img img {
  width: 100%;
}
.gayn-web .building-img {
  position: relative;
}
.gayn-web .building-head-text {
    /* position: absolute; */
    /* top: 110px; */
    text-align: center;
    margin: 2% 0%;
    left: 0px;
    right: 0px;
    color: #3cadb5;
}
.gayn-web .building-img img {
  width: 100%;
}
.gayn-web .text-box h3 {
  color: #3cadb5;
  margin-bottom: 1rem;
  font-weight: 400;
}
.gayn-web .clr-blue {
  color: #3cadb5;
}
.gayn-web .small-box {
  background-image: url('./assets/round.png');
  background-repeat: no-repeat;
  background-size: 100% 100%,cover;
  width: 115px;
  height: 115px;
  margin-right: 25px;
  box-shadow: 8px 10px 20px 5px #b6b3b3;
  border-radius: 50%;
}
.gayn-web .space-three p {
  margin-bottom: 0px;
  padding: 5px 0px;
}
.gayn-web .border-blue {
  border-bottom: 2px solid #3CADB5;
  width: 50%;
}
.gayn-web .box-space-one {
  margin-bottom: 30px;
}
.gayn-web .space-three {
  margin-top: 25px;
}
.gayn-web .rounded-box-sec p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  height: 100%;
  text-align: center;
}
.gayn-web .msg-bg-one {
  background-image: url('./assets/sms-black.png');
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.gayn-web .bg-sms p {
  color: #fff;
  padding: 8px 5px;
  margin-bottom: 18px;
  text-align: center;
}
.gayn-web .bg-sms {
  width: 270px;
  margin-right: -2px;
}
.gayn-web .msg-bg-two {
  background-image: url('./assets/sms-blue.png');
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.gayn-web .msg-bg-three {
  background-image: url('./assets/sms-blue-right.png');
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.gayn-web .msg-bg-four {
  background-image: url('./assets/sms-black-right.png');
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.gayn-web .message-sec {
  margin-top: 30px;
}
.gayn-web .man-image img {
  width: 100%;
}
.gayn-web .letter-img img {
  width: 100%;
}
.gayn-web .logo-img-sec {
  text-align: center;
}
.gayn-web .logo-img-sec img {
  width: 45%;
}
.gayn-web .letter-img {
  position: relative;
}
.gayn-web .logo-img-sec {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -110px;
}
.gayn-web .invest-btn-sec {
  margin-top: 40px;
}
.gayn-web .invest-btn-sec button {
  background-image: linear-gradient(#3ba6ae, #283742);
  border: 3px solid #38959e;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  padding: 8px 30px;
  border-radius: 12px;
  letter-spacing: 1.5px;
  box-shadow: 7px 7px 12px 4px #b4b1b1;
  font-family: "Montserrat";
}
.gayn-web .build-position img {
  width: 100%;
}
.gayn-web .build-position {
  position: relative;
}
.gayn-web .reason-text-position {
    position: absolute;
    color: #fff;
    left: 4%;
    top: 8%;
}
.gayn-web .reason-text-position h1 {
  font-weight: 400;
  letter-spacing: 2px;
}
.gayn-web .point-img img {
  width: 80%;
}
.gayn-web .center-sec {
  justify-content: center;
}
.gayn-web .point-sec {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: -13%;
  right: 0px;
  transform: translateY(-50%);
}
.gayn-web .dot-sec {
  transform: rotate(28deg);
}
.gayn-web .dot-text-sec {
  padding-left: 4%;
}
.gayn-web .point-img {
  margin-bottom: 95px;
}
.gayn-web .point-box {
  margin-bottom: 70px;
}
.gayn-web .point-text p {
  margin-bottom: 0px;
}
.gayn-web .space-sec-one {
    margin-top: 20px;
    margin-left: 60px;
}
.gayn-web .space-sec-two {
  margin-left: -10px;
}
.gayn-web .space-sec-three {
  margin-left: -80px;
}
.gayn-web .space-sec-four {
  margin-left: -155px;
}
.gayn-web .hand-img-sec {
    position: absolute;
    right: 10%;
    bottom: 0px;
}
.gayn-web .build-position .hand-img {
  width: 90%;
}
.gayn-web .partner-img img {
  width: 100%;
}
.gayn-web .team-head-sec h6 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}
.gayn-web .team-head-sec p {
  font-weight: 600;
}
.gayn-web .card-box {
  margin-top: 12px;
}
.gayn-web .card-box h6 {
  font-weight: 700;
  font-size: 20px;
}
.gayn-web .name-sec-box {
  padding: 25px 10px 20px 20px;
  border-bottom: 2px solid #cec4c4;
  margin-bottom: 20px;
  border-left: 2px solid #f3f0f0;
  border-right: 0.2px solid #f3f0f0;
}
.gayn-web .name-sec-box p {
  color: #777;
  margin-bottom: 6px;
}
.gayn-web .name-sec-box h5 {
  font-weight: 600;
}
.gayn-web .social-icon i {
    color: #3cadb5;
    font-weight: 700;
    padding-right: 18px;
    font-size: 20px;
}
.gayn-web .designation {
  font-size: 14px;
}
.gayn-web .border-blue-sec {
  border: 3px solid #3cadb5;
}
.gayn-web .box-bottom {
  padding: 15px 12px;
  height: 100%;
  display: flex;
  align-items: center;
}
.gayn-web .box-bottom p {
    margin-bottom: 0px;
    font-weight: 600;
}
.gayn-web .border-black-sec {
  border: 3px solid #000;
}
.gayn-web .space-bottom {
  padding-top: 8%;
  padding-bottom: 6%;
}
.footer-sec {
  background-color: #0f131f;
  padding: 3% 0%;
}
.gayn-web .bottom-logo p {
  color: #f0f1f1;
  margin-bottom: 0px;
  margin-top: 8px;
}
.gayn-web .bottom-logo p a {
  color: #fff;
  text-decoration: none;
}
.gayn-web .right-icons i {
  color: #fff;
  font-size: 22px;
  margin-right: 20px;
}
.gayn-web .space-between-sec {
  justify-content: space-between;
  align-items: center;
}
.grid_scetion.light_white .icons .grid_list.hide-icon::before {
  display: none;
}
.gayn-web .gayn-inform {
  position: relative;
  padding-bottom: 5%;
}
.gayn-web .left-cornor-round img {
  width: 100%;
}
.gayn-web .left-cornor-round {
    position: absolute;
    left: 0px;
    top: 0px;
}
.gayn-web .right-cornor-round {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.gayn-web .right-cornor-round img {
  width: 100%;
}
.grid_scetion.light_dark .card::after {
  content: "";
    width: 12px;
    height: 24px;
    /* background-color: #fff; */
    position: absolute;
    bottom: -24px;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    z-index: -1;
}
.grid_scetion.light_dark .card.before-sec::after {
  content: none;
}
.list-space li {
  margin-bottom: 1rem;
}
.bg-theme-one {
  background-image: url('./assets/img/Asset_11.png');
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.bg-theme-two {
  background-image: url('./assets/img/Asset_10.png');
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.bg-theme-three {
  background-image: url('./assets/img/Asset_11.png');
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
}
.center-align {
  align-items: center;
}
.grid_scetion.light_dark .clr-font .card-body p {
  color: #efeff0;
}
.clr-font h4 {
  color: #3cadb5;
}
.gradiant-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #226070;
  top: 0px;
}
.letter-space {
  letter-spacing: 1px;
}
.margin-space p {
  margin-bottom: 0px;
}
/* .font-border {
  font-size: 13px;
} */
.list-space {
  padding-left: 15px;
}
.bg-svg {
  background-color: #0f131f;
  padding-top: 8%;
  padding-bottom: 4%;
}
.bg-svg .react-svg-drawing {
  stroke: #fff !important;
  stroke-width: 0.5px !important;
}
.video-res {
  width: 100%;
}
/* Boo style end */


/* Gayn start */

.grid_scetion{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}

.grid_scetion.light_dark {
  background-color: #0f131f;
  color: #fff;
}
.grid_scetion.light_dark p.p_bigf {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.grid_scetion.light_dark p.p_bigf span{
  color: #55aeb5;
}

.grid_scetion.light_dark h3 {
  text-align: center;
  color: #55aeb5;
  margin-bottom: 20px;
}


.grid_scetion.light_dark h1 {
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 30px;
}

.grid_scetion.light_dark img.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
}

.grid_scetion.light_dark .grid_list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

/* .grid_scetion.light_dark .grid_list span {
  position: absolute;
  top: 13px;
  text-align: center;
  right: 0;
  left: 0;
  bottom: 0;
  color: #000;
} */

.grid_scetion.light_dark .grid_list img{
   width: 100%;
}

.grid_scetion.light_dark .card {
  margin-bottom: 1.5rem !important;
  z-index: 1;
  text-align: center;
  background-color: #00b0b7;
  border: 0px solid rgb(0 0 0 / 0%);
  border-radius: 1rem;
  box-shadow: 0px 10px 20px #cbced300, inset 2px 2px 4px rgb(239 242 247 / 0%), inset -4px -4px 8px #f6f9ff00;
  width: 80%;
  margin: auto;
}


.grid_scetion.light_dark .card-body {
  text-align: center;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.grid_scetion.light_dark .card-body p{
  padding: 15px 25px;
  color: #000;
  font-weight: 500;
}
.grid_scetion.light_dark .card-body h4 {
  background-color: #ededed;
  color: #000;
  padding: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1.2rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  font-weight: 600;
}

.grid_scetion.light_white{
  background-color: #ededed;
  color: #000;
}

.grid_scetion.light_white h3{
  font-size: 2rem;
}

.grid_scetion.light_white h3 span {
  color: #55aeb5;
}

.grid_scetion.light_white .icons {
  margin-top: 60px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.grid_scetion.light_white .icons .grid_list{
  text-align: center;
}

.grid_scetion.light_white .icons .grid_list span {
  font-size: 4rem;
  color: #d5d5d5;
  position: relative;
  top: -20px;
}

.grid_scetion.light_white .icons .grid_list p {
  font-size: 1.4rem;
  width: 60%;
  margin: 0 auto;
  line-height: 1.1;
}

.grid_scetion.light_white .icons .grid_list{
  position: relative;
}

.grid_scetion.light_white .icons .grid_list::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.1em solid black;
  border-top: 0.1em solid black;
  transform: rotate(45deg);
  margin-right: 1em;
  font-size: 3rem;
}

.grid_scetion.img_banner_big{
   position: relative;
   padding: 0px;
}

.grid_scetion.img_banner_big img.img_icons{
   width: 100%;
}

.grid_scetion.img_banner_big img.bottom_banner_bg {
  width: 45%;
  position: absolute;
  z-index: 1;
  margin: 0 auto;
  display: block;
  left: 0;
  right: 0;
  top: 6%;
}
.grid_scetion.img_banner_big h4 {
  position: absolute;
  /* top: unset; */
  bottom: 0;
  height: auto;
  left: 0;
  right: 0;
  text-align: center;
  /* vertical-align: middle; */
  font-size: 3rem;
  width: 70%;
  margin: 0 auto;
  bottom: 200px;
  color: #fff;
}

.grid_scetion.light_dark.light_dark2{
  background-color: #0e101b;
}

.grid_scetion.light_dark h1 span{
  color: #55aeb5;
}

.grid_scetion.light_dark .font_p_18 p {
  font-size: 22px;
  margin-bottom: 20px;
}

.grid_scetion.light_dark .font_p_18 p span.light_blue{
  color: #55aeb5;
  font-weight: 600;
}

.grid_scetion.light_dark .font_p_18 {
  padding: 0px 5rem;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat', sans-serif;
}

.grid_scetion.light_dark img.gayn_logo {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.grid_scetion.light_dark .card.trans {
  background-color: #1a1c28;
  border: 2px solid #00b0b7;
  border-bottom-right-radius: 40px !important;
  border-radius: 10px;
  height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid_scetion.light_dark .card.trans .card-body h4 {
  background-color: transparent;
  color: unset;
  
  padding-bottom: 0px;
}

.grid_scetion.light_dark .card.trans .card-body p{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
}

.grid_scetion.light_dark .card.trans span.sqr {
  position: absolute;
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
  background-color: #00b0b7;
  left: -25px;
  bottom: 35px;
  border-radius: 2px;
  z-index: 9;
}

.grid_scetion.light_white .icons .grid_list.first::before{
  display: none !important;
}

.grid_scetion.light_dark .card.trans span.sqr::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-left: 0.1em solid #f1e2dd;
  border-top: 0.1em solid #f1e2dd;
  transform: rotate(45deg);
  font-size: 3rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
}

.grid_scetion.img_banner_big h5 {
  position: absolute;
  top: 60px;
  bottom: 0;
  height: auto;
  left: 50px;
  right: 0;
  text-align: left;
  font-size: 2rem;
  width: 85%;
  bottom: unset;
  color: #fff;
  font-weight: 600;
  z-index: 9;
  text-shadow: 4px 4px 6px #000;
}

.grid_scetion.img_banner_big.bg_gradient{
  position: relative;
}

.grid_scetion.img_banner_big.bg_gradient::before {
  background: linear-gradient(to bottom, rgb(30 30 30 / 5%), rgb(14 16 27 / 41%));
  width: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 1;
}

.grid_scetion.light_dark.light_dark2 ul.list_inset {
  padding-left: 0px;
  list-style: none;
  width: 70%;
  margin-right: 0;
  display: block;
  float: right;
  margin-top: 40px;
}

.grid_scetion.light_dark.light_dark2 ul.list_inset li {
  padding: 15px 10px;
  border: 1px solid #ffffff;
  margin-bottom: 15px;
  display: block;
  text-align: center;
  transition: 1s;
  cursor: pointer;
  font-size: 20px;
  background-color: #262632;
  color: #57b1b7;
  font-weight: 700;
}

.grid_scetion.light_dark.light_dark2 ul.list_inset li:hover {
  background-color: #57b1b7;
  color: #0f131f;
}

.grid_scetion.light_dark.light_dark2 p.font_p_18 {
  font-size: 20px;
  padding-left: 0px !important;
}

.grid_scetion.light_dark.light_dark2 img.assets_38 {
  width: 85%;
  position: absolute;
  z-index: 1;
  right: -5px;
  top: -368px;
}


.grid_scetion.light_dark.light_dark2 .grid_content_bx { 
  /* position: absolute; */
  /* top: -300px; */
  right: -36px;
  z-index: 1;
  /* width: 50%; */
  margin-left: auto;
  margin-top: -300px;
  display: block;
  left: 0px;
  right: -5px;
  background-image: url('./assets/img/assets_38.png');
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  padding: 6% 10%;
}
.box-align {
  text-align: right;
}

.grid_scetion.light_dark.light_dark2 .grid_content_bx h3 {
  font-size: 2.5rem;
  text-align: left;
}

.grid_scetion.light_dark.light_dark2 .grid_content_bx p {
  font-size: 16px;
  line-height: 1.5;
  padding-right: 4rem;
  text-align: left;
  }

.grid_scetion.light_dark.light_dark2 img.gayn_logo {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.grid_scetion.light_dark .card.trans.no_border{
    background-color: transparent;
    border: 0px solid #00b0b7;
    border-bottom-right-radius: 0px !important;
    border-radius: 0;
}

.grid_scetion.light_dark .card.trans.no_border h4{
  padding-top: 0px;
  color: #55aeb5;
}

.grid_scetion.light_dark .card.trans.no_border p{
  padding: 10px;
}

.grid_scetion.light_dark img.top_left_img {
  position: absolute;
  top: -2px;
  left: 0;
}

.grid_scetion.light_dark img.bottom_right_img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.grid_scetion.light_dark.light_dark2 .grid_content_bx span.blue{
  color: #55aeb5;
}
.grid_scetion.light_dark .grid_list p {
  padding: 12px 4px;
  margin-bottom: 20px;
  color: #464751;
  font-weight: 600;
}

/* Gayn end */

/* Boo Media style start */
@media screen and (min-width: 1000px) and (max-width: 1350px) {
  .building-head-text h3 {
    font-size: 20px;
  }
  .gayn-web .building-head-text {
    /* top: 83px; */
}
}
@media screen and (min-width: 1001px) and (max-width: 1400px) {
  .gayn-web .space-sec-one {
    margin-top: 15px;
    margin-left: 20px;
  }
  .gayn-web .space-sec-two {
    margin-left: -24px;
  }
  .gayn-web .space-sec-three {
    margin-left: -70px;
  }
  .gayn-web .space-sec-four {
    margin-left: -104px;
  }
  .gayn-web .build-position .hand-img {
    width: 55%;
  }
  .gayn-web .reason-text-position h1 {
    font-size: 28px;
  }
  .gayn-web .hand-img-sec {
    right: -6%;
    bottom: 0px;
  }
  .gayn-web .point-img {
    margin-bottom: 50px;
  }
  .gayn-web .point-img img {
    width: 65%;
  }
  .gayn-web .point-box {
    margin-bottom: 15px;
  }
  .gayn-web .space-sec-one {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1130px) {
  .gayn-web .point-sec {
    left: -6%;
  }
}
@media screen and (min-width: 1140px) and (max-width: 1300px) {
  .gayn-web .point-sec {
    left: -12%;
  }
}

@media screen and (max-width: 1000px) {
  .gayn-web .building-head-text {
    position: inherit;
    padding: 5% 0% 0% 0%;
    top: inherit;
  }
  .gayn-web .right-cornor-round img {
    width: 70%;
  }
  .gayn-web .right-cornor-round {
    text-align: right;
  }
  .gayn-web .logo-img-sec {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 50px;
}
.gayn-web .letter-img img {
  width: 70%;
}
.gayn-web .letter-img .logo-img-sec img {
  width: 40%;
}
.gayn-web .letter-img {
  text-align: center;
}
.grid_scetion.img_banner_big h4 {
  position: absolute;
  width: 85%;
  bottom: inherit;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  font-size: 30px;
  line-height: 45px;
}
.grid_scetion.light_dark.light_dark2 .grid_content_bx {
  top: 0px;
  padding: 8% 8%;
  position: relative;
}
.grid_scetion.light_dark.light_dark2 p.font_p_18 {
  padding-left: 0px !important;
}
.grid_scetion.light_dark.light_dark2 ul.list_inset {
  width: 100%;
}
.grid_scetion.light_dark.light_dark2 .grid_content_bx {
  margin-left: inherit;
  margin: auto;
}
.gayn-web .hand-img-sec {
  position: absolute;
  right: 120px;
  bottom: 0px;
  text-align: right;
}
.gayn-web .build-position .hand-img {
  width: 45%;
}
.gayn-web .reason-text-position h1 {
  font-size: 24px;
}
.gayn-web .point-img img {
  width: 50%;
}
.gayn-web .point-text p {
  font-size: 12px;
}
.gayn-web .point-img {
  margin-bottom: 40px;
}
.gayn-web .point-sec {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: -4%;
  right: 0px;
  transform: translateY(-50%);
}
.gayn-web .point-box {
  margin-bottom: 16px;
}
.gayn-web .space-sec-one {
  margin-left: 10px;
}
.gayn-web .space-sec-one {
  margin-top: 2px;
}
.gayn-web .space-sec-two {
  margin-left: -25px;
}
.gayn-web .space-sec-three {
  margin-left: -65px;
}
.gayn-web .space-sec-four {
  margin-left: -102px;
}
.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

}
@media screen and (min-width: 800px) and (max-width: 999px) {
  .gayn-web .point-sec {
    left: -7% !important;
  }
}
@media screen and (max-width: 600px) {
  .banner-sec-img img {
    width: 100%;
  }
  .gayn-web .logo-img-sec {
    position: inherit;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  .gayn-web .box-space-one {
    margin-top: 30px;
  }
  .gayn-web .small-box {
    width: 145px;
    height: 145px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .rounded-box-sec .row {
    justify-content: center;
  }
  .gayn-web .right-cornor-round img {
    width: 50%;
  }
  .message-sec .row {
    justify-content: center;
  }
  .grid_scetion.img_banner_big h5 {
    position: absolute;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%) !important;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
  .grid_scetion.light_dark img.bottom_right_img {
    width: 12%;
  }
  .grid_scetion.light_dark .font_p_18 {
    padding: 0px 2rem;
  }
  .grid_scetion.light_dark h1 {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 25px;
  }
  .grid_scetion {
    padding-top: 45px;
    padding-bottom: 50px;
    position: relative;
  }
  .grid_scetion.light_dark .grid_list span {
    top: 22px;
  }
  .grid_scetion.light_dark .grid_list {
    margin-top: 15px;
  }
.grid_scetion.light_dark h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.grid_scetion.light_white h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.grid_scetion.img_banner_big h4 {
  width: 95%;
  bottom: inherit;
  color: #fff;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%) !important;
  line-height: 30px;
  text-shadow: 2px 2px #000;
}
.grid_scetion.light_white .icons .grid_list {
  margin-bottom: 30px;
}
.grid_scetion.light_white .icons .grid_list::before {
  display: none;
}
.grid_scetion.light_dark img.top_left_img {
  width: 12%;
}
.building-head-text h3 {
  font-size: 22px;
}
.gayn-web .letter-img {
  text-align: center;
}
.gayn-web .letter-img img {
  width: 85%;
}
.gayn-web .letter-img .logo-img-sec img {
  width: 50%;
}
.grid_scetion.light_dark.light_dark2 .grid_content_bx {
  position: relative;
  top: 0px;
  right: 0px;
  width: 100%;
}
.grid_scetion.light_dark.light_dark2 .grid_content_bx h3 {
  font-size: 1.5rem;
}
.grid_scetion.light_dark.light_dark2 p.font_p_18 {
  padding-left: 0px !important;
}
.grid_scetion.light_dark.light_dark2 ul.list_inset {
  width: 100%;
}
.grid_scetion.light_dark.light_dark2 .grid_content_bx p {
  padding-right: 0rem;
}
.gayn-web .point-sec {
  position: inherit;
  top: 0%;
  margin: 0 auto;
  left: 0%;
  right: 0px;
  transform: translateY(0%);
  margin-top: 25px;
}
.gayn-web .dot-sec {
  transform: rotate(0deg);
  text-align: center;
}
.gayn-web .space-sec-one, .gayn-web .space-sec-two, .gayn-web .space-sec-three, .gayn-web .space-sec-four {
  margin-left: 0px;
}
.gayn-web .dot-text-sec {
  padding-left: 0%;
}
.gayn-web .build-position .hand-img {
  display: none;
}
.gayn-web .point-img {
  margin-bottom: 33px;
}
.gayn-web .reason-text-position h1 {
  font-size: 22px;
}
.gayn-web .center-sec {
  justify-content: center;
  align-items: self-start;
}
.gayn-web .center-sec {
  align-items: self-start;
}
.gayn-web .space-sec-one {
  margin-top: 0px;
}
.footer-sec {
  padding: 3% 2%;
}
.grid_scetion.light_dark .grid_list {
  margin-bottom: 25px;
}
.grid_scetion.light_dark .card {
  width: 100%;
}
.grid_scetion.light_dark.light_dark2 ul.list_inset li {
  font-size: 16px;
}
.grid_scetion.light_dark .font_p_18 p {
  font-size: 20px;
}


}
@media screen and (max-width: 380px) {
  .gayn-web .right-cornor-round img {
    width: 35%;
  }
  .gayn-web .bottom-logo p {
    margin-bottom: 15px;
  }
  .grid_scetion.light_dark .grid_list span {
    top: 14px;
  }
}

/* Boo Media style End */



