.css-19bb58m{
    font-size: 16px !important;
    
}
.kl-form__submit-button{
    background-color: #3DAEB6 !important;
    border-color:#3DAEB6 !important;
}
.kl-input .form-control.picklist{
    font-size: 16px !important;
}

.kl-form__branding{
    display: none !important;
}
.kl__form-container h5 {
    color: "#3DAEB6" !important;

}
.kl-form__form-title
{

    font-size: "20px" !important;
    font-weight: bold !important;
}
.kl-form .kl-input input{
    font-size: 16px !important;
}

@media only screen and (max-width: 600px) {
    .kl-form .kl-input input{
        font-size: 14px !important;
    }
    .kl-input .form-control.picklist{
        font-size: 14px !important;
    }
}